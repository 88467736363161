// アップロードされたCSVファイルを扱うモデル
import {parse} from 'csv-parse';
import gs1Validator from '../mixins/DeviceId';
import Vue from 'vue';

const DELIVERY_ORG_NUMBER_INDEX = 0; // 配送先医療機関No.のインデックス
// const DELIVERY_ORG_NAME_INDEX = 1; // 配送先医療機関名のインデックス
// const STAFF_DEPARTMENT_INDEX = 2; // 注文者所属部署
// const STAFF_NAME_INDEX = 3; // 注文者氏名
// const STAFF_NAME_KANA_INDEX = 4; // 注文者氏名(カナ)
// const STAFF_PHONE_NUMBER_INDEX = 5; // 注文者電話番号
// const STAFF_POSTAL_CODE_INDEX = 6; // 注文者郵便番号
// const STAFF_ADDRESS_INDEX = 7; // 注文者住所
const STAFF_EMAIL_INDEX = 8; // 注文者メールアドレス
// const BARCODE_INDEX = 9; // デバイスバーコード
// const SHIPPED_INDEX = 10; // 販売事業者出荷日
// const SALES_ORG_ORDER_ID_INDEX = 11; // 販売事業者注文ID

// 存在確認がとれた組織
let existingOrgs = [];

// CSVを解析し、ヘッダーとデータの配列を格納するオブジェクトを返す
export const parseCSV = (file) => {
  return new Promise( async (resolve, reject) => {
      const csv = await file.text();
      parse(csv, {
        bom: true,
        columns: true,
        relax_column_count: true, // 個数が足りない場合は警告を出さない
        skip_empty_lines: true,
      }, (err, csvData) => {
        // console.log('parseCSV', err, csvData);
        if (err) {
          reject(err);
        } else {
          // CSVデータの要素数が最も大きいもののキーを抽出する
          const maxColumnsRecord = csvData.reduce((acc, cur) => {
            return Object.keys(acc).length > Object.keys(cur).length ? acc : cur;
          });
          resolve({ headers: Object.keys(maxColumnsRecord), data:csvData });
        }
      });

  });
};

// 必須項目用のチェックとして、空データであるかを判定
// 結果はエラーメッセージを返す
const emptyCheck = (value, index, fieldName, source) => {
  if  (value == null || value.trim().length === 0) {
    return {
      index,
      message: `【${value} → × 】${fieldName}は入力必須項目です。`,
      source
    };
  } else false;
}

// 指定した医療機関識別Noの組織の存在の有無を返す
const orgExists = async (ecMedicalOrgId) => {
  let result = false;
  if (existingOrgs.includes(ecMedicalOrgId)) return true;
  
  const db = Vue.prototype.$db;  
  const querySnapshot = await db.collection('customer_orgs')
    .where('ec_medical_id', '==', ecMedicalOrgId)
    .where('deleted', '==', null)
    .get();
  result = querySnapshot.size > 0;
  existingOrgs.push(result);
  return result;
}

// ホルターの存在の有無を返す
const holterExists = async (deviceId) => {
  const db = Vue.prototype.$db;  
  const doc = await db.collection('devices').doc(deviceId).get();
  if (doc.exists) {
    // console.log('holterExists', doc.data());
    // deletedがnullの時存在
    return doc.get('deleted') === null;
  }
  return false;
}

const validateRecord = async (record, invalidOrgs) => {
  const functions = Vue.prototype.$functions;  
  // レコードのチェック
  // レコードのフォーマットが正しいかどうかをチェックする
  const keys = Object.keys(record);
  const values = Object.values(record);
  const source = values.join(',') // 本来ならばファイルの中身を表示するべき
  if (keys.length < 12) {
    // 個数が足りないエラー
    return {
      index: null, 
      message: 'レコード内の項目数は12個以上必要です。このレコードのフォーマットは正しくありません。',
      source
    };
  }
  
  // メールアドレスの重複がある組織のレコードの場合、エラーを返す
  if (Object.keys(invalidOrgs).includes(record[keys[DELIVERY_ORG_NUMBER_INDEX]])) {
    return {
      index: null,
      message: `【${record[keys[STAFF_EMAIL_INDEX]]} → × 】${keys[STAFF_EMAIL_INDEX]}が、このCSVファイル中の他の組織で使用されているため。登録できません。`,
      source
    }
  }

  let index = 0;
  // フィールド項目0のチェック
  //  医療機関識別No.
  let emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
  if (emptyCheckResult) return emptyCheckResult;
  if (record[keys[index]]) {
    // 英数字7桁以上10桁以下を正規表現でテスト
    if (!/^[a-zA-Z0-9]{7,10}$/.test(record[keys[index]])){        
      return {
        index, 
        message: `【${record[keys[index]]} → × 】${keys[index]}は英数字7桁以上10桁以下で入力してください。`,
        source
      };
    }
  
    // 存在なしチェック
    const deliveryOrgNumber = record[keys[index]];
    const is_first_org = !(await orgExists(deliveryOrgNumber));
    // console.log('is_first_org', is_first_org);
    index++;
    // フィールド項目1のチェック
    //  提供先名
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
      
    // 50文字以内を正規表現でテスト
    if (!/^.{1,50}$/.test(record[keys[index]])){        
      return {
        index, 
        message: `【${record[keys[index]]} → × 】${keys[index]}は50文字以内で入力してください。`,
        source
      };
    }    

    index++;
    // フィールド項目2のチェック
    //  注文者所属部署
    // 100文字以内を正規表現でテスト
    if (!/^.{0,100}$/.test(record[keys[index]].trim())){
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}は100文字以内で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目3のチェック
    //  注文者氏名
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);    
    if (emptyCheckResult) return emptyCheckResult;
    // 1文字以上、50文字以内を正規表現でテスト
    if (!/^.{1,50}$/.test(record[keys[index]])){
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}は50文字以内で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目4のチェック
    //  注文者氏名（フリガナ）
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
    // 1文字以上、50文字以内を正規表現でテスト
    if (!/^.{1,50}$/.test(record[keys[index]])){
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}は50文字以内で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目5のチェック
    //  注文者電話番号
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
      // 電話番号をで21桁以内でェック
    if (!(10 <= record[keys[index]].length && record[keys[index]].length <= 21)) {
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}は、10桁以上21桁以内で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目6のチェック
    // 郵便番号
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
    // 郵便番号を正規表現でテスト
    if (!/^\d{3}-\d{4}$/.test(record[keys[index]])){
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}は、NNN-NNNNの形式で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目7のチェック
    // 住所
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
    if (record[keys[index]].length > 50) {
      return {
        index,
        message: `【${record[keys[index]]} : {${record[keys[index]].length} 文字 → × 】${keys[index]}は50文字以内で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目8のチェック
    // 注文者Emailアドレス
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
    if (record[keys[index]].length > 254) {
      return {
        index,
        message: `【${record[keys[index]]} : {${record[keys[index]].length} 文字 → × 】${keys[index]}は254文字以内で入力してください。`,
        source
      };
    }
    // メールアドレスを正規表現でテスト
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(record[keys[index]])){
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}は、正しい形式(ユーザ名@ドメイン)ではありません。`,
        source
      };
    }
    // 未登録の組織の場合、メールアドレスがcustomer_orgs, staffs, customer_staffsにおいて未登録であること
    if (is_first_org) {
      const email = record[keys[index]].toLowerCase();  // メールアドレスは小文字にする
      const checkKey = String(deliveryOrgNumber) + String(email);
      if (checkKey in isCheckMails) {
        if (isCheckMails[checkKey] === false) {
          return {
            index,
            message: `【${record[keys[index]]} → × 】${keys[index]}は、他の組織で既に使用されています。`,
            source
          };
        }
      } else {
        let isDuplicated = await functions.isDuplicatedEmail({
          email,
          checkOrgCollection: 'customer_orgs'}
        );
        if (isDuplicated.data.result === 'Registerd') {
          isCheckMails[checkKey] = false;
          return {
            index,
            message: `【${record[keys[index]]} → × 】${keys[index]}は、他の組織で既に使用されています。`,
            source
          };
        }
        isCheckMails[checkKey] = true;
      }
    }

    index++;
    // フィールド項目9のチェック
    // バーコード
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
    // ToDo: GS1形式のチェック
    const gs1Codes =gs1Validator.methods.validateGs1(record[keys[index]]);
    if (gs1Codes == null) {
      return {
        index,
        message: `【${record[keys[index]]} → × 】${keys[index]}の値が正しくありません。`,
        source
      };
    }
    // デバイス種別毎のチェック
    if (gs1Validator.methods.getAryProductCds('ecg').includes(gs1Codes[1])) {
      // 商品コードが心電計の場合
      // デバイスの存在チェック
      const deviceId = gs1Codes[4];
      if (await holterExists(deviceId)) {
        return {
          index,
          message: `【デバイスID${deviceId} → × 】このデバイスは、既に登録されています。`,
          source
        };
      }
      // デバイスの期限切れチェック
      const expirationDate = new Date(`20${gs1Codes[2].substr(0, 2)}`, gs1Codes[2].substr(2, 2), 0, 23, 59, 59);
      if (expirationDate < new Date()) {
        return {
          index,
          message: `【デバイスID${deviceId} → × 】このデバイスは、使用期限が過ぎています。`,
          source
        };
      }
    } else if (gs1Validator.methods.getAryProductCds('dongle').includes(gs1Codes[1])) {
      // 商品コードがドングルの場合
    } else {
      return {
        index,
        message: `【プロダクトコード ${gs1Codes[1]} → × 】${keys[index]}のプロダクトコードが正しくありません。`,
        source
      };
    }

    index++;
    // フィールド項目10のチェック
    // 販売事業者出荷日
    if (record[keys[index]]) {
      // 日付を正規表現でテスト
      if (!( // 以下のいずれかの条件を満たした場合はエラーなし
          /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(record[keys[index]]) // YYYY/MM/DD
        ||
          /^\d{4}-\d{1,2}-\d{1,2}$/.test(record[keys[index]])   // YYYY-MM-DD
        )) {
        return {
          index,
          message: `【${record[keys[index]]} → × 】${keys[index]}は、YYYY/MM/DDの形式で入力してください。`,
          source
        };
      }
    }

    index++;
    // フィールド項目11のチェック
    // 販売事業者注文ID
    emptyCheckResult = emptyCheck(record[keys[index]], index, keys[index], source);
    if (emptyCheckResult) return emptyCheckResult;
    if (record[keys[index]].length > 19) {
      return {
        index,
        message: `【${record[keys[index]]} : {${record[keys[index]].length} 文字 → × 】${keys[index]}は、19文字以内で入力してください。`,
        source
      };
    }

    index++;
    // フィールド項目12のチェック
    // デバイス受付センターID
    if (record[keys[index]]) {
      // 存在する場合
      // 開発環境で利用するためバリデーションは不要
    }
  }
  // フォーマットがすべて正しい場合はtrueを返す
  // フォーマットが正しくない場合はエラーの配列を返す
  return true;
}

  // 組織単位で最初のレコードのメールアドレスをすべて抽出し、
  // 他の組織で同一メールアドレスがあるかどうかをチェックする
  // 同一のものが発覚した場合は、その組織に関するレコードを無効とする
const validateDuplicatedEmails = async (records) => {
  // 最初の組織のメールアドレスをすべて抽出 key = email, value = orgId
  let firstOrgEmails = [];
  let checkedOrgsNumbers = {};
  let invalidOrgs = {};
  for (let record of records) {
    const keys = Object.keys(record);
    let orgNumber = record[keys[DELIVERY_ORG_NUMBER_INDEX]];
    let email = record[keys[STAFF_EMAIL_INDEX]].toLowerCase();  // メールは小文字にする
    const checkedOrgNumbersKeys = Object.keys(checkedOrgsNumbers);
    if (!checkedOrgNumbersKeys.includes(orgNumber)) {
      // 重複チェック
      if (firstOrgEmails.includes(email)) {
        // 重複あり
        checkedOrgNumbersKeys.forEach((key) => {
          const value = checkedOrgsNumbers[key];
          if (value === email) {
            invalidOrgs[key] = email;
          }
        });
        invalidOrgs[orgNumber] = email;
      }
      // はじめての組織ならば、最初のemailを取得
      firstOrgEmails.push(email);
      // チェック済みとして医療機関織No.を記録
      checkedOrgsNumbers[orgNumber] = email;
    }
  }
  return invalidOrgs;
};

// CSVデータの検証を行い、レコード単位の結果を配列で返す
let isCheckMails = {};  // メールチェック実施済み確認用
export const validateCSVData = async (data) => {
  if (!Array.isArray(data)) throw new Error('data is not array type');
  // それぞれのレコードのバリデーション
  const validRecords = [];
  const invalidRecords = [];
  // 空設定
  existingOrgs = [];
  // CSVファイル内で異なる組織の先頭レコードで同一メールアドレスがあるかどうかをチェックする
  // 結果はキー: 医療機関組織No.、値: emailの連想配列
  const invalidOrgs = await  validateDuplicatedEmails(data);

  isCheckMails = {};
  for (let record of data) {
    const result = await validateRecord(record, invalidOrgs);
    if (result === true) {
      validRecords.push(record);
    } else {
      invalidRecords.push(result);
    }
  }
  return {validRecords, invalidRecords};
};