var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',{staticClass:"headline",attrs:{"text":"","color":"black"}},[_vm._v("CSVアップロード")]),_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"text-body-1 mt-2"},[_vm._v("CSVファイルを選択して、アップロードするデータに問題ないかチェックの上、[登録]ボタンをクリックしてください。")]),_c('p',{staticClass:"text-body-2"},[_vm._v("※ 正常なレコードのみ登録されます。")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-file-input',{attrs:{"prepend-icon":"mdi-file-delimited-outline","placeholder":"ここをクリックしてファイルを選択してください。","truncate-length":"64","show-size":"","accept":"text/csv"},on:{"change":_vm.onFileChanged},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),(_vm.uploadState=='confirm')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"primary"}},[_c('v-card-title',{staticClass:"white--text"},[_vm._v("正常なレコード")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"px-1 pb-1",attrs:{"dense":"","headers":_vm.validHeaders,"items":_vm.dispValidData,"items-per-page":_vm.selectedLinesPerPage,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.selectedLinesPerPage=$event},"update:items-per-page":function($event){_vm.selectedLinesPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page-options":_vm.linesPerPageList},on:{"update:options":updateOptions}})]}}],null,false,1622243515)})],1)],1)],1)],1):_vm._e(),(_vm.uploadState=='confirm' && _vm.invalidData.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"error"}},[_c('v-card-title',{staticClass:"white--text"},[_vm._v("不正なレコード")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v("※ 以下のレコードは登録されません。")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"px-1 pb-1",attrs:{"dense":"","headers":_vm.invalidHeaders,"items":_vm.invalidData,"items-per-page":_vm.selectedLinesPerPage,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.selectedLinesPerPage=$event},"update:items-per-page":function($event){_vm.selectedLinesPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page-options":_vm.linesPerPageList},on:{"update:options":updateOptions}})]}}],null,false,1622243515)})],1)],1)],1)],1):_vm._e(),(_vm.uploadState=='confirm')?_c('v-card-actions',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"normal"},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v(" cancel ")]),_vm._v(" キャンセル ")],1),_c('v-btn',{staticClass:"ml-4 px-6",attrs:{"color":"primary","disabled":_vm.isRegisterCSVBtnDisabled || !_vm.validData || _vm.validData.length == 0},on:{"click":_vm.registerCSV}},[_c('v-icon',[_vm._v(" upload ")]),_vm._v("  登録  ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }