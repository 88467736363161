// GS1コードの商品コード
const DEVICE_PRODUCT_CDS = {
  ECG: '001|006|008|009',
  DONGLE: '002',
}

// デバイスIDの接頭辞
const DEVICE_TYPE_STRINGS = {
  ECG: 'T',
  DONGLE: 'D',
}

// デバイスIDの正規表現オブジェクト
const REG_OBJ_DEVICE_IDS = {
  // 共通
  COMMON: new RegExp(`^[${DEVICE_TYPE_STRINGS.DONGLE}|${DEVICE_TYPE_STRINGS.ECG}]-([0-9]{6}|[A-Z][0-9A-Z]{6})$`),
  // 心電計
  ECG: new RegExp(`^${DEVICE_TYPE_STRINGS.ECG}-([0-9]{6}|[A-Z][0-9A-Z]{6})$`),
  // ドングル
  DONGLE: new RegExp(`^${DEVICE_TYPE_STRINGS.DONGLE}-([0-9]{6}|[A-Z][0-9A-Z]{6})$`),
}

// シリアル番号の正規表現オブジェクト
const REG_OBJ_SERIAL_IDS = {
  // 心電計
  ECG: new RegExp(`^([0-9]{6}|[A-Z][0-9]{6})$`),
}

// GS1コードの正規表現オブジェクト
const REG_OBJ_GS1_CDS = {
  // 共通
  COMMON: new RegExp('^01([0-9]457154372[0-9]{4})17([0-9]{6})10([0-9]{5})21([0-9]{6}|[A-Z][0-9A-Z]{6})$'),
  COMMON_WITH_FNC1: new RegExp('^01([0-9]457154372[0-9]{4})17([0-9]{6})10([0-9]{5})(?<fnc1>029)21([0-9]{6}|[A-Z][0-9A-Z]{6})$'),  // 制御コード付き
  // 心電計
  ECG: new RegExp(`^01([0-9]457154372(${DEVICE_PRODUCT_CDS.ECG})[0-9]{1})17([0-9]{6})10([0-9]{5})21([0-9]{6}|[A-Z][0-9A-Z]{6})$`),
  ECG_WITH_FNC1: new RegExp(`^01([0-9]457154372(${DEVICE_PRODUCT_CDS.ECG})[0-9]{1})17([0-9]{6})10([0-9]{5})(?<fnc1>029)21([0-9]{6}|[A-Z][0-9A-Z]{6})$`),  // 制御コード付き
  // ドングル
  DONGLE: new RegExp(`^01([0-9]457154372(${DEVICE_PRODUCT_CDS.DONGLE})[0-9]{1})10([0-9]{5})$`)
}

export default {
  data() {
    return {
    }
  },
  computed: {
    DEVICE_PRODUCT_CDS() {
      return DEVICE_PRODUCT_CDS
    },
    DEVICE_TYPE_STRINGS() {
      return DEVICE_TYPE_STRINGS
    },
    REG_OBJ_DEVICE_IDS() {
      return REG_OBJ_DEVICE_IDS
    },
    REG_OBJ_SERIAL_IDS() {
      return REG_OBJ_SERIAL_IDS
    },
    REG_OBJ_GS1_CDS() {
      return REG_OBJ_GS1_CDS
    },
  },
  methods: {
    // GS1コードの検証
    validateGs1(cd) {
      if (!cd || cd === null) return null;
      cd = cd.trim();

      let cd01, cd17, cd10, cd21, productCd;

      let cds = cd.match(REG_OBJ_GS1_CDS.ECG);
      if (cds === null || cds.length !== 6) {
        cds = cd.match(REG_OBJ_GS1_CDS.DONGLE);
        if (cds === null || cds.length !== 4) return null;
        cd01 = cds[1];
        productCd = cds[2];
        cd10 = cds[3];
      } else {
        cd01 = cds[1];
        productCd = cds[2];
        cd17 = cds[3];
        cd10 = cds[4];
        cd21 = cds[5];
      }

      // チェックデジット検証
      if (!this.checkJan14Cd(cd01)) return null;

      // 商品コードからデバイス種別の判定
      let deviceType = '';
      if (this.getAryProductCds('ecg').includes(productCd)) {
        // 商品コードが心電計の場合
        deviceType = DEVICE_TYPE_STRINGS.ECG;
        // 製造年月日チェック
        if (!this.isDate(this.gs1Cd10ToProduced(cd10))) return null;
        // 使用期限年月日チェック(日は固定で00のため便宜的に1日で検証する)
        if (!this.isDate(`20${cd17.substr(0, 2)}/${cd17.substr(2, 2)}/01`)) return null;
      } else if (this.getAryProductCds('dongle').includes(productCd)) {
        // 商品コードがドングルの場合
        deviceType = DEVICE_TYPE_STRINGS.DONGLE;
      }
      // ECG[梱包インジゲータ、商品コード、使用期限日、製造日、デバイスID]
      // DONGLE[梱包インジゲータ、商品コード、null, ロット番号, D + '-'-'null']
      return [cd01.substr(0, 1), productCd, cd17, cd10, deviceType + '-' + cd21];
    },
    // GS1のJAN14コードのチェックデジット検証
    checkJan14Cd(cd14) {
      if (!cd14 || cd14 === null) return null;
      cd14 = cd14.trim();
      if (cd14.length !== 14) return null;

      // 先頭13桁のコード
      const cd13 = cd14.substr(0, 13);
      // 末尾1文字はチェックデジット
      const checkDigit = Number(cd14.substr(13, 1));

      // 13桁のコードを1文字づつ分解
      const arrayCd13 = [...cd13];
      // 偶数桁を足し算
      let tmpEven = Number(arrayCd13[0]) + Number(arrayCd13[2]) + Number(arrayCd13[4])
                      + Number(arrayCd13[6]) + Number(arrayCd13[8]) + Number(arrayCd13[10]) + Number(arrayCd13[12]);
      tmpEven = tmpEven * 3;  // 足したものを3倍する
      // 奇数桁を足し算
      let tmpOdd = Number(arrayCd13[1]) + Number(arrayCd13[3]) + Number(arrayCd13[5])
                      + Number(arrayCd13[7]) + Number(arrayCd13[9]) + Number(arrayCd13[11]);
      // 偶数桁と奇数桁の結果を足して1文字づつ分解
      let arrayTmp = [...String(tmpEven + tmpOdd)]
      // 最後の1文字を数値化
      let tmpCheckDigit = Number(arrayTmp[arrayTmp.length - 1]);
      // 10からその数値を引いたものがチェックデジット
      tmpCheckDigit = 10 - tmpCheckDigit;
      // 10の場合はチェックデジットは0とする
      if (tmpCheckDigit === 10) tmpCheckDigit = 0;

      if (checkDigit === tmpCheckDigit) return true;
      return false;
    },
    // GS1コードの（10）から製造年月日を得る
    gs1Cd10ToProduced(gs1Cd10) {
      let mm = gs1Cd10.substr(3, 2);
      let dd = gs1Cd10.substr(0, 2);
      let y = gs1Cd10.substr(2, 1);
      let now = new Date();
      let currentYear = String(now.getFullYear());
      let yyy = currentYear.substr(0, 3);
      let yyyy = String(yyy) + String(y);
      if (now < new Date(yyyy, mm - 1, dd)) {
        // 製造年月が現在より新しい場合は、10年前とする
        yyyy  = yyyy - 10;
      }
      return `${yyyy}/${mm}/${dd}`;
    },
    // 年月日文字列（'yyyy/mm/dd'）の妥当性チェック
    isDate(strDate) {
      if( !strDate || strDate.trim() == '') return false;
      if (!strDate.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/)) return false
      const date = new Date(strDate);
      if (date.getFullYear() !=  strDate.split('/')[0]
          || date.getMonth() != strDate.split('/')[1] - 1
          || date.getDate() != strDate.split('/')[2]
      )  return false;
      return true;
    },
    getAryProductCds(type) {
      if (type == 'ecg') {
        return DEVICE_PRODUCT_CDS.ECG.split('|').map( v => v.trim() );
      } else if (type == 'dongle') {
        return DEVICE_PRODUCT_CDS.DONGLE.split('|').map( v => v.trim() );
      }
      return [];
    },
  }
}
