<template>
    <v-container fluid>
      <h2 text color="black" class="headline">CSVアップロード</h2>
      <v-card class="pa-2" elevation="0">
        <v-row>
            <v-col cols="6">
              <p class="text-body-1 mt-2">CSVファイルを選択して、アップロードするデータに問題ないかチェックの上、[登録]ボタンをクリックしてください。</p>
              <p class="text-body-2">※ 正常なレコードのみ登録されます。</p>
            </v-col>
            <v-col cols="6" >
              <v-file-input
                v-model="file"
                prepend-icon="mdi-file-delimited-outline"
                placeholder="ここをクリックしてファイルを選択してください。"
                truncate-length="64"
                show-size
                accept="text/csv"
                @change="onFileChanged"
              ></v-file-input>
            </v-col>
        </v-row>
        <v-row v-if="uploadState=='confirm'">
          <v-col cols="12" >
            <v-card color="primary" >
              <v-card-title class="white--text">正常なレコード</v-card-title>
              <v-card-text class="pa-0">
                <v-data-table
                  dense
                  :headers="validHeaders"
                  :items="dispValidData"
                  :items-per-page.sync=selectedLinesPerPage
                  hide-default-footer
                  class="px-1 pb-1"
                >
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                      :items-per-page-options="linesPerPageList"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="uploadState=='confirm' && invalidData.length > 0">
          <v-col cols="12" class="">
            <v-card color="error">
              <v-card-title class="white--text">不正なレコード</v-card-title>
              <v-card-subtitle class="white--text">※ 以下のレコードは登録されません。</v-card-subtitle>
              <v-card-text class="pa-0">
                <v-data-table
                  dense
                  :headers="invalidHeaders"
                  :items="invalidData"
                  :items-per-page.sync=selectedLinesPerPage
                  hide-default-footer
                  class="px-1 pb-1"
                >
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                      :items-per-page-options="linesPerPageList"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions v-if="uploadState=='confirm'" class="mt-4">
          <v-spacer></v-spacer>
          <v-btn
            color="normal"
            @click="cancel"
          >
            <v-icon>
              cancel
            </v-icon>
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            class="ml-4 px-6"
            @click="registerCSV"
            :disabled="isRegisterCSVBtnDisabled || !validData || validData.length == 0"
          >
            <v-icon>
              upload
            </v-icon>
            &nbsp;登録&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
</template>

<script>
import { parseCSV, validateCSVData } from '@/models/UploadedCSV';
import DeviceId from '@/mixins/DeviceId';

export default {
  name: 'csv-upload',
  mixins: [
    DeviceId
  ],
  components: {

  },
  data () {
    return {
      uploadState: "select", // "select", "confirm", "finished"
      csvData: [],
      csvHeaders: [],
      validHeaders: [],
      dispValidData: [],
      validData: [],
      invalidData: [],
      file: null,
      isRegisterCSVBtnDisabled: false,
      linesPerPageList: [10, 30, 50],
      selectedLinesPerPage: 10,
    }
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const customerStaffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          customerStaffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    },
  },
  methods: {
    async onFileChanged(file) {
      if (file != null) {
        this.file = file;

        this.dispValidData = [];
        this.validData = [];
        this.invalidData = [];

        // console.log('onFileChanged', file);
        this.uploadState = "confirm";
        // CSVを解析してヘッダーとデータに分類
        let result;
        try {
          result = await parseCSV(file);
        } catch (e) {
          console.error(e.code, e.message);
          this.uploadState = "select";
          return;
        }
        this.csvHeaders = result.headers;
        this.csvData = result.data;

        // バリデーション（正常レコードと、例外レコードの分離）
        const {validRecords, invalidRecords} = await this.$root.$progressive(validateCSVData(this.csvData));

        this.validData = validRecords;

        // 表示用データの作成
        for (const record of this.validData) {
          const keys = Object.keys(record);
          // 心電計型式の設定
          let ecgType = ''
          const gs1Result = this.validateGs1(record[keys[9]]);
          if (gs1Result && gs1Result[1]) {
            // 心電計型式の取得
            const ecgTypeId = this.$store.state.itemCd2EcgType[gs1Result[1]]
            ecgType = this.$store.state.ecgTypes[ecgTypeId]
          }
          // ※ recordにそのまま設定すると元のデータも変わってしまう
          const tmp = Object.assign({}, record)
          tmp['型式'] = ecgType
          this.dispValidData.push(tmp)
        }

        this.validHeaders = [];
        this.csvHeaders.forEach(header => {
          this.validHeaders.push({
            text: header,
            value: header,
          });
        });
        // 心電計型式列の追加（表示のみ）
        this.validHeaders.push({
          text: '型式',
          value: '型式',
        })

        this.invalidData = invalidRecords;

        this.invalidHeaders = [{
          text: 'エラーが発生した行',
          value: 'source',
          width: '50%',
        },
        {
          text: 'エラー内容',
          value: 'message',
          width: '50%',
        }];

      } else {
        this.uploadState = "select";
      }
    },
    cancel() {
      this.uploadState = "select";
      this.file = null;
    },
    // CSV登録のためのPromiseオブジェクトを返す
    async getRegisterCSVPromise() {
      // CSVファイルをupload_csv_historyに登録
      const source = await this.file.text();
      return new Promise(async (resolve, reject) => {
          try {
            await this.$db.doc(`csv_upload_history/${this.file.name}`).set({
              file_name: this.file.name,
              uploaded: this.$firebase.firestore.FieldValue.serverTimestamp(),
              records: this.validData,
              headers: this.csvHeaders,
              source,
              sales_org_id: this.$store.state.userOrg.id, // staff_orgs/{id}
              sales_org_name: this.$store.state.userOrg.name,
              upload_staff_id: this.$store.state.user.id, // staffs/{id}
              upload_staff_name: this.$store.state.user.name,
              finished: null, // 終了時はnull以外
              errors: [],
              warnings: [],
            });
            const unsubscribe = this.$db.doc(`csv_upload_history/${this.file.name}`).onSnapshot(snapshot => {
              // 結果を監視
              if (!snapshot.exists) {
                // console.log('upload finished');
                unsubscribe();
                reject([{
                  name: 'CSV_FILE_WAS_DELETED',
                  message: 'アップロードされたCSVデータが削除されています。',
                }]);
              } else {
                const data = snapshot.data();
                if (data.finished != null) {
                  unsubscribe();
                  // 成功しても、警告を返す
                  if (data.errors.length > 0) {
                    reject(data.errors);
                  } else {
                    resolve(data.warnings);
                  }
                }
              }
            });
          } catch (e) {
            console.error(e);
            // エラーをそのまま返す
            reject([
              {
                e: e.name,
                message: e.message
              }
            ]);
          }
        });
    },
    async checkDongles() {
      // すべてのドングルレコードをチェック
      let result = [];
      // 同一条件
      // （医療機関識別No., ）販売事業者出荷日, 販売事業者注文ID, ロットID
      // 医療機関識別No.は、販売事業者注文IDが決定すれば一意なので、条件に不要
      const DELIVERY_ORG_NAME_INDEX = 1; // 機関名
      const BARCODE_INDEX = 9; // デバイスバーコード
      const SHIPPED_INDEX = 10; // 出荷日
      const SALES_ORG_ORDER_ID_INDEX = 11; // 販売事業者注文ID

      for (let i = 0; i < this.csvData.length; i++) {
        const record = this.csvData[i];
        const deliveryOrgName = record[this.csvHeaders[DELIVERY_ORG_NAME_INDEX]];
        const barcode = record[this.csvHeaders[BARCODE_INDEX]].trim();
        const shipped = record[this.csvHeaders[SHIPPED_INDEX]].trim();
        const salesOrgOrderId = record[this.csvHeaders[SALES_ORG_ORDER_ID_INDEX]].trim();

        // デバイスの種別を判定
        if (!this.REG_OBJ_GS1_CDS.DONGLE.test(barcode)) {
          // ドングル以外は対象外
          continue;
        }
        // ロットIDの抽出
        let gs1Result = this.validateGs1(barcode);
        // エラーが発生する場合は無視
        if (gs1Result === null) continue;
        // ロットIDの取得
        const lotId = gs1Result[3];
        // csv_upload_ordersコレクションの販売事業者注文IDから重複対象のドキュメントを取得
        const querySnapshot = await this.$db.collection('csv_upload_orders')
          .where('sales_org_id', '==', this.$store.state.userOrg.id)
          .where('sales_org_order_id', '==', salesOrgOrderId)
          .get();
        for (let orderIndex = 0; orderIndex < querySnapshot.size; orderIndex++) {
          const doc = querySnapshot.docs[orderIndex];
          if (!doc.exists) continue;
          const data = doc.data();
          // console.log(data['shipped'] === shipped, lotId, data['delivery_dongles'].includes(lotId));
          // 出荷日が同じかつ、ロットIDが同じものを含むなら、重複とみなす
          if (data['shipped'] === shipped
              && Array.isArray(data['delivery_dongles']) && data['delivery_dongles'].includes(lotId)){
            // 同じ文字列となるので、
            result = result.concat(
              (i + 1) + '行目:', '　' + `${deliveryOrgName}, 注文ID: ${salesOrgOrderId},`
              , '　' + `出荷日: ${shipped}, ロットID: ${lotId}`,
              '　');
          }
        }
      }
      return result;
    },
    async registerCSV() {
      // 処理中の場合は終了
      if (this.isRegisterCSVBtnDisabled) return;
      this.isRegisterCSVBtnDisabled = true;
      try {
        // 指定された関数の実行
        await this.registerCSVBody();
      } finally {
        // 処理中の解除
        this.isRegisterCSVBtnDisabled = false;
      }
    },
    async registerCSVBody() {
      // ファイルの存在チェック
      const doc = await this.$db.doc(`csv_upload_history/${this.file.name}`).get();
      if (doc.exists) {
        await this.$root.$alert_multi_line('エラー',
          [
            `同一名のファイル"${this.file.name}"が既にアップロードされています。`,
            'ファイル名を変更する等の対応が必要です。'
          ]);
        // ファイルが存在する場合は、登録済みとして処理を終了する
        return;
      }

      // 登録対象の「通信用ドングル/ソフト」と以下の全ての項目が同一の「通信用ドングル /ソフト」が
      // 登録済みである場合に警告をポップアップで表示する。OK であればその まま登録する。
      // - 配送先の医療機関, 販売事業者出荷日, 販売事業者注文ID, ロットID
      const duplicateDongles = await this.checkDongles();
      // console.log('duplicateDongles', duplicateDongles);
      if (duplicateDongles.length > 0) {
        const confirmResult = await this.$root.$confirm_multi_line('警告',
        [
          '以下の「通信用ドングル/ソフト」は、既に登録されている可能性があります。それでも登録しますか？', '　', '【重複のおそれがある通信用ドングル/ソフト】',
          ...duplicateDongles
        ]);
        if (!confirmResult) {
          return;
        }
      }
      try {
        // resultにはエラーを格納する配列が入る
        const result = await this.$root.$progressive(
          this.getRegisterCSVPromise()
        );
        if (result) {

          // 操作履歴
          this.$functions.log({
            tags: ['csv'],
            message: `${this.file.name}：CSVをアップロードしました。`,
          });

          const messages = ['登録を完了しました。', '　', result.length > 0 ? '【警告一覧】' : ''];
          result.forEach(warning => {
            messages.push(`・${warning.message}`);
            messages.push("　");
          });
          await this.$root.$alert_multi_line(result.length > 0 ? '警告あり': '通知', messages);
          this.uploadState = "finished";
          this.file = null;
        }
      } catch (e) {
        // エラーが発生した場合は、エラーを表示する
        console.error(e);
        const messages = [];
        e.forEach(error => {
          messages.push(`・${error.message}`);
          messages.push("　");
        });
        await this.$root.$alert_multi_line('エラー', messages);
      }

      // this.uploadState = "finished";
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
  },
}

</script>

<style scoped>
.v-data-table {
  overflow-wrap: anywhere;
}
</style>